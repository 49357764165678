import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  Grid,
  Icon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DocumentReference, getDoc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import Passageiro, {
  StatusCredencial,
  statusCredencial,
} from "../../models/passageiro";
import Residencial from "../../models/residencial";
import ResidencialSelect from "../residencial/residencialSelect";
import moment from "moment";
import { Badge, ExpandMore } from "@mui/icons-material";
import Administrador from "../../models/administrador";
import AdministradorDb from "../../db/administradorDb";
import { useAuth } from "../auth/authProvider";
import avatarColorGenerator from "../../util/avatarColorGenerator";
import UploadAvatar from "./uploadAvatar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import DialogExcluirUsuario from "./dialogExcluirUsuario";

export function DialogUsuario({
  open,
  onClose,
  usuario,
}: {
  open: boolean;
  onClose: () => void;
  usuario?: Passageiro;
}) {
  const [nome, setNome] = useState(usuario?.nome ?? "");
  const [email, setEmail] = useState(usuario?.email ?? "");
  const [quadra, setQuadra] = useState(usuario?.quadra ?? "");
  const [lote, setLote] = useState(usuario?.lote ?? "");
  const [residencialSelecionado, setResidencialSelecionado] = useState<
    DocumentReference<Residencial> | undefined
  >(usuario?.residencial ?? undefined);
  const [administrador, setAdministrador] = useState<Administrador>();
  const [credencial, setCredencial] = useState(usuario?.credencial ?? null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useAuth();
  const [salvando, setSalvando] = useState(false);
  const [openExcluir, setOpenExcluir] = useState(false);
  useEffect(() => {
    if (!!usuario?.credencial)
      getDoc(usuario.credencial.emitidaPor!).then((doc) =>
        setAdministrador(doc.data())
      );
  }, [usuario?.credencial]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <FormGroup>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <UploadAvatar
                  disabled={!!!usuario}
                  onSave={(file) => {
                    setCanvas(file);
                  }}
                >
                  <Avatar
                    sx={{ width: 160, height: 160 }}
                    src={canvas?.toDataURL() || usuario?.fotoDePerfil}
                  />
                </UploadAvatar>
                {!!usuario && (
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      py={2}
                    >
                      <Icon
                        fontSize="large"
                        color={(function () {
                          switch (statusCredencial(credencial)) {
                            case StatusCredencial.EMITIDA:
                              return "success";
                            case StatusCredencial.NAO_EMITIDA:
                              return "disabled";
                            case StatusCredencial.CANCELADA:
                              return "error";
                            case StatusCredencial.VENCIDA:
                              return "warning";
                          }
                        })()}
                      >
                        <Badge fontSize="large" />
                      </Icon>
                      <Box ml={1} />
                      <Button
                        variant="contained"
                        color={(function () {
                          switch (statusCredencial(credencial)) {
                            case StatusCredencial.EMITIDA:
                              return "success";
                            case StatusCredencial.NAO_EMITIDA:
                              return "secondary";
                            case StatusCredencial.CANCELADA:
                              return "error";
                            case StatusCredencial.VENCIDA:
                              return "warning";
                          }
                        })()}
                        endIcon={<ExpandMore />}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        {(function () {
                          switch (statusCredencial(credencial)) {
                            case StatusCredencial.EMITIDA:
                              return "Emitida";
                            case StatusCredencial.NAO_EMITIDA:
                              return "Não Emitida";
                            case StatusCredencial.CANCELADA:
                              return "Cancelada";
                            case StatusCredencial.VENCIDA:
                              return "Vencida";
                          }
                        })()}
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          selected={
                            credencial?.status === StatusCredencial.EMITIDA
                          }
                          onClick={() => {
                            setCredencial((prev) => ({
                              ...prev,
                              status: StatusCredencial.EMITIDA,
                              emitidaEm: moment().toDate(),
                              emitidaPor: new AdministradorDb().doc({
                                administradorId: auth.usuario!.uid,
                              }),
                              validade: moment()
                                .add(2, "year")
                                .endOf("month")
                                .toDate(),
                            }));
                            setAnchorEl(null);
                          }}
                        >
                          <Typography variant="body2">
                            {credencial?.status === StatusCredencial.EMITIDA
                              ? "Prorrogar"
                              : "Emitir"}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          selected={
                            credencial?.status === StatusCredencial.CANCELADA
                          }
                          onClick={() => {
                            setCredencial((prev) => ({
                              ...prev,
                              status: StatusCredencial.CANCELADA,
                              emitidaEm: moment().toDate(),
                              emitidaPor: new AdministradorDb().doc({
                                administradorId: auth.usuario!.uid,
                              }),
                              validade: null,
                            }));
                            setAnchorEl(null);
                          }}
                        >
                          <Typography variant="body2">Cancelar</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                    {credencial?.status === StatusCredencial.EMITIDA && (
                      <>
                        <Typography
                          color="textSecondary"
                          variant="caption"
                          mb={-0.5}
                        >
                          Valida até
                        </Typography>
                        <Typography color="textPrimary" gutterBottom>
                          {moment(usuario?.credencial?.validade).format(
                            "MM/YY"
                          )}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                                fontSize: 12,
                                backgroundColor: avatarColorGenerator(
                                  administrador?.ref.id || ""
                                ),
                              }}
                            >
                              {administrador?.nome[0]}
                            </Avatar>
                            <Box mr={1} />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              fontSize={12}
                            >
                              {administrador?.nome}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs>
              <Box display="flex" flexDirection="column">
                <TextField
                  label="Nome"
                  autoComplete="name"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <Box pb={1} />

                <TextField
                  label="Email"
                  autoComplete="email"
                  disabled={!!usuario}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Box pb={1} />

                <ResidencialSelect
                  value={residencialSelecionado}
                  onSelect={(residencial) =>
                    setResidencialSelecionado(residencial)
                  }
                />

                <Box pb={1} />

                <TextField
                  label="Quadra"
                  value={quadra}
                  onChange={(e) => setQuadra(e.target.value)}
                />

                <Box pb={1} />

                <TextField
                  label="Lote"
                  value={lote}
                  onChange={(e) => setLote(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ width: "100%", gap: 1 }}>
        {!!usuario && (
          <>
            <Button
              size="medium"
              variant="contained"
              color={"error"}
              onClick={() => setOpenExcluir(true)}
            >
              {"Excluir"}
            </Button>
            <DialogExcluirUsuario
              usuario={usuario}
              open={openExcluir}
              onClose={() => {
                setOpenExcluir(false);
                onClose();
              }}
            />
          </>
        )}
        <Box flexGrow={1} />
        <Button
          size="medium"
          variant="text"
          color={"inherit"}
          onClick={onClose}
        >
          {"Cancelar"}
        </Button>
        {!!usuario ? (
          <LoadingButton
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!nome}
            loading={salvando}
            onClick={async () => {
              if (!!residencialSelecionado) {
                let fotoDePerfil = usuario.fotoDePerfil;
                if (!!canvas) {
                  setSalvando(true);
                  const storageRef = ref(
                    getStorage(),
                    `${usuario.ref.path}/foto.jpg`
                  );

                  fotoDePerfil = await new Promise<Blob>((resolve, reject) => {
                    canvas.toBlob((blob) => {
                      if (!!blob) resolve(blob);
                      else reject();
                    });
                  })
                    .then((imageBlob) =>
                      uploadBytes(storageRef, imageBlob, {
                        contentType: "image/jpeg",
                      })
                    )
                    .then((u) => getDownloadURL(u.ref));
                }
                await setDoc(
                  usuario.ref,
                  new Passageiro({
                    ...usuario,
                    nome,
                    quadra,
                    lote,
                    residencial: residencialSelecionado,
                    credencial,
                    fotoDePerfil,
                  })
                )
                  .then(() => setSalvando(false))
                  .then(onClose);
              }
            }}
          >
            {"Salvar"}
          </LoadingButton>
        ) : (
          <LoadingButton
            size="medium"
            variant="contained"
            color={"success"}
            disabled={
              !!!nome ||
              !!!email ||
              !!!residencialSelecionado ||
              !!!quadra ||
              !!!lote
            }
            loading={salvando}
            onClick={() => {
              setSalvando(true);
              httpsCallable<
                {
                  nome: string;
                  email: string;
                  residencialId: string;
                  quadra: string;
                  lote: string;
                },
                void
              >(
                getFunctions(),
                "usuario-criarPassageiro"
              )({
                nome,
                email,
                residencialId: residencialSelecionado!.id,
                quadra,
                lote,
              })
                .then(() => setSalvando(false))
                .then(onClose);
            }}
          >
            {"Adicionar"}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
