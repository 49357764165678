import {
  Avatar,
  Box,
  Fab,
  Icon,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { getDoc } from "firebase/firestore";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { Add, Badge, Search, SearchRounded } from "@mui/icons-material";
import Passageiro, {
  StatusCredencial,
  statusCredencial,
} from "../../models/passageiro";
import PassageiroDb from "../../db/passageiroDb";
import Residencial from "../../models/residencial";
import avatarColorGenerator from "../../util/avatarColorGenerator";
import { DialogUsuario } from "./dialogUsuario";
import { useHits, useInstantSearch, useSearchBox } from "react-instantsearch";
import { Hit } from "algoliasearch/lite";
import { LoadingButton } from "@mui/lab";

export default function Passageiros() {
  const { query: searchValue, refine } = useSearchBox();
  const { items } =
    useHits<Pick<Passageiro, "email" | "nome" | "fotoDePerfil">>();
  const { status } = useInstantSearch();
  const [editarPassageiro, setEditarPassageiro] = useState<Passageiro>();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(searchValue);

  const handleSearch = () => {
    refine(inputValue);
  };

  return (
    <Box>
      <Box pb={2} display="flex" flexDirection="row" gap={1}>
        <TextField
          id="standard-email"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          required
          placeholder="Email ou Nome"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={inputValue}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setInputValue(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") handleSearch();
          }}
        />
        <LoadingButton
          onClick={handleSearch}
          loading={status === "loading" || status === "stalled"}
          variant="contained"
          color="info"
          startIcon={<SearchRounded />}
        >
          Buscar
        </LoadingButton>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Passageiro</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Residencial</TableCell>
              <TableCell>Quadra</TableCell>
              <TableCell>Lote</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(items, (item) => {
              return (
                <RowHit
                  key={item.objectID}
                  hit={item}
                  onClick={(pas) => setEditarPassageiro(pas)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!!editarPassageiro && (
        <DialogUsuario
          key="editar"
          open={!!editarPassageiro}
          onClose={() => {
            setEditarPassageiro(undefined);
            handleSearch();
          }}
          usuario={editarPassageiro}
        />
      )}
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      {open && (
        <DialogUsuario key="criar" open={open} onClose={() => setOpen(false)} />
      )}
    </Box>
  );
}

function RowHit({
  hit,
  onClick,
}: {
  hit: Hit<Pick<Passageiro, "nome" | "email" | "fotoDePerfil">>;
  onClick: (passageiro: Passageiro) => void;
}) {
  const [passageiro, setPassageiro] = useState<Passageiro>();
  const [residencial, setResidencial] = useState<Residencial>();

  useEffect(() => {
    getDoc(new PassageiroDb().doc({ passageiroId: hit.objectID })).then((doc) =>
      setPassageiro(doc.data())
    );
  }, [hit.objectID]);

  useEffect(() => {
    if (!!passageiro?.residencial && !!!residencial) {
      getDoc(passageiro.residencial).then((doc) => setResidencial(doc.data()));
    }
  }, [passageiro?.residencial, residencial]);

  return (
    <TableRow
      key={hit.objectID}
      onClick={() => passageiro && onClick(passageiro)}
      hover
      style={{ cursor: "pointer" }}
    >
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(hit.objectID) }}
            src={hit.fotoDePerfil}
          >
            {hit.nome[0]}
          </Avatar>
          <Box mr={1} />
          {hit.nome}
        </Box>
      </TableCell>
      <TableCell>{hit.email}</TableCell>
      <TableCell>
        {!!!passageiro || !!!residencial ? (
          <Skeleton width={100} />
        ) : (
          residencial?.nome
        )}
      </TableCell>
      <TableCell>
        {!!!passageiro || !!!residencial ? (
          <Skeleton width={80} />
        ) : (
          passageiro?.quadra
        )}
      </TableCell>
      <TableCell>
        {!!!passageiro || !!!residencial ? (
          <Skeleton width={80} />
        ) : (
          passageiro?.lote
        )}
      </TableCell>
      <TableCell>
        <Tooltip
          title={(function () {
            switch (statusCredencial(passageiro?.credencial ?? null)) {
              case StatusCredencial.EMITIDA:
                return "Emitida";
              case StatusCredencial.NAO_EMITIDA:
                return "Não Emitida";
              case StatusCredencial.CANCELADA:
                return "Cancelada";
              case StatusCredencial.VENCIDA:
                return "Vencida";
            }
          })()}
        >
          <Icon
            color={(function () {
              switch (statusCredencial(passageiro?.credencial ?? null)) {
                case StatusCredencial.EMITIDA:
                  return "success";
                case StatusCredencial.NAO_EMITIDA:
                  return "disabled";
                case StatusCredencial.CANCELADA:
                  return "error";
                case StatusCredencial.VENCIDA:
                  return "warning";
              }
            })()}
          >
            <Badge />
          </Icon>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
